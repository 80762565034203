<template>
  <div class='newLogin'>
    <div class="login-inputs">
      <el-form size="medium"
               :model="ruleForm"
               :rules="rules"
               ref="LoginRuleForm">
        <!-- 登录模块儿 -->
        <div class="loginBox"
             v-if="showForget">
          <div class="login-title"><img src="../../assets/img/inshoot-logo.png"
                 alt=""></div>
          <!-- 手机登录 -->
          <div v-if="!passwordLogin">
            <el-form-item class="form-item-box"
                          prop="phoneNumber"
                          label="手机号">
              <el-input v-model="ruleForm.phoneNumber"
                        maxlength="11"
                        clearable
                        placeholder="请输入手机号登录">
              </el-input>
            </el-form-item>
            <el-form-item class="form-item-box yzm-item"
                          label="验证码">
              <el-input v-model="ruleForm.verifyCode"
                        maxlength="4"
                        placeholder="请输入验证码">
              </el-input>
              <el-button class="get-code-btn"
                         @click="getVerifyCode('login')"
                         :disabled="disBtn">{{resetInfor}}</el-button>
            </el-form-item>
          </div>
          <!-- 密码登录 -->
          <div v-else>
            <el-form-item class="form-item-box"
                          label="账号">
              <el-input type="text"
                        v-model="ruleForm.accountNumber"
                        placeholder="请输入账号">
              </el-input>
            </el-form-item>
            <el-form-item class="form-item-box"
                          label="密码">
              <el-input v-model="ruleForm.password"
                        :type="loginFlag?'text' : 'password'"
                        id="password"
                        maxlength="16"
                        :readonly="passwordReadonly"
                        placeholder="请输入密码">
                <template #suffix>
                  <i class="iconfont eyes-icon"
                     :class="loginFlag?'hs-icon-zhengyan':'hs-icon-biyan'"
                     @click="showPwd('login')"></i>
                </template>
              </el-input>
            </el-form-item>
          </div>
          <div class="login-btn">
            <el-button type="primary"
                       :class="(!passwordLogin && (!ruleForm.phoneNumber || !ruleForm.verifyCode)) || (passwordLogin && (!ruleForm.accountNumber || !ruleForm.password))?'disable-btn' : ''"
                       :disabled="(!passwordLogin && (!ruleForm.phoneNumber || !ruleForm.verifyCode)) || (passwordLogin && (!ruleForm.accountNumber || !ruleForm.password))"
                       @click="goLogin">登录</el-button>
          </div>
          <!-- <div class="login-type-btns">
            <el-button type="text"
                       @click="changePassword">{{loginText}}</el-button>
            <el-button type="text"
                       @click="forgetPassword">忘记密码？</el-button>
          </div> -->
        </div>
        <!-- 忘记密码 -->
        <!-- <div class="forget-password"
             v-else>
          <div class="goLoginBox">
            <i class="iconfont hs-icon-fanhui"
               @click="goBackLoginBox"></i>
          </div>
          <div class="contentInputs">
            <div class="login-title">忘记密码</div>
            <el-form-item class="form-item-box"
                          prop="forgetPhone"
                          label="手机号">
              <el-input v-model="ruleForm.forgetPhone"
                        maxlength="11"
                        clearable
                        placeholder="请输入手机号">
              </el-input>
            </el-form-item>
            <el-tooltip placement="bottom"
                        trigger="manual"
                        content="密码由6-16位英文字母、数字和特俗符号（~@#等）组成"
                        v-model="newPasswordTip">
              <el-form-item class="form-item-box"
                            prop="forgePassword"
                            label="新密码">
                <el-input v-model="ruleForm.forgePassword"
                          id="newPassword"
                          @input="passwordChange"
                          @keyup="strong"
                          @focus="showVisble"
                          minlength="6"
                          maxlength="16"
                          :type="forgetFlag?'text' : 'password'"
                          :readonly="NewpasswordReadonly"
                          placeholder="请输入新密码">
                  <template #suffix>
                    <i class="iconfont eyes-icon"
                       :class="forgetFlag?'hs-icon-zhengyan':'hs-icon-biyan'"
                       @click="showPwd('forget')"></i>
                  </template>
                </el-input>
              </el-form-item>
            </el-tooltip>
            <div class="strength_box"
                 v-if="passwordStrongShow">
              密码强度：
              <span class="ml5">{{strength}}</span>
              <span class="password_border ml5">
                <span v-for="item in 15"
                      :key='item'
                      class="defBackground"
                      :class="(score >= 14 && score <= 19 && item <4) ? 'red':((score< 32 && score > 19) && item <9) ? 'yellow': ((score>= 32) && item <14)  ? 'green':''"></span>
              </span>
            </div>
            <el-form-item class="form-item-box yzm-item"
                          label="验证码">
              <el-input v-model="ruleForm.forgetVerifyCode"
                        maxlength="4"
                        placeholder="请输入验证码">
              </el-input>
              <el-button class="get-code-btn"
                         @click="getVerifyCode('forget')"
                         :disabled="disBtn">{{resetInfor}}</el-button>
            </el-form-item>
            <div class="login-btn">
              <el-button type="primary"
                         @click="forgetLogin"
                         :class="!ruleForm.forgetPhone || !ruleForm.forgePassword || !ruleForm.forgetVerifyCode ? 'disable-btn' : ''"
                         :disabled="!ruleForm.forgetPhone || !ruleForm.forgePassword || !ruleForm.forgetVerifyCode">登录</el-button>
            </div>
          </div>
          <div class="has-number">
            已有账号? <span @click="goBackLoginBox">立即登录</span>
          </div>
        </div> -->
      </el-form>
      <div class="verify-code-box"
           v-show="dialogVisible">
        <div class="box-main">
          <i class="el-icon-close closeicon"
             @click="dialogVisible = false"></i>
          <p class="verify-code-box-title">请完成安全验证</p>
          <div id="verify-code"></div>
        </div>
      </div>
    </div>
    <a href="//beian.miit.gov.cn/" target="_blank" class="icp">京ICP备18059447号-1</a>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, toRefs, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import md5 from 'js-md5'
export default defineComponent({
  components: {
  },
  setup (props, context) {
    const phoneNumberReg = (rule, value, callback) => { // 手机验证
      const telValue = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/g
      if (!telValue.test(value)) {
        callback(new Error('请输入正确的手机号'))
      } else {
        callback()
      }
    }
    const Passwordreg = (rule, value, callback) => { // 密码验证
      const passValue = /^[a-z0-9A-Z~!@#%^&*()_;:.=+/]{1,16}$/g
      if (!passValue.test(value) || value.length < 6) {
        callback(new Error('6-16位英文字母、数字和特殊符号(~@#等)组成'))
      } else {
        callback()
      }
    }
    const LoginRuleForm = ref(null) // 表单ref
    const registerNameForm = ref(null)
    const router = useRouter()
    const { proxy } = getCurrentInstance()

    onMounted(() => {
      // 防止密码自动写入
      setTimeout(() => {
        state.passwordReadonly = false
      }, 500)
    })
    const state = reactive({ // 定义变量
      ruleForm: {
        phoneNumber: '', // 手机号
        verifyCode: '', // 验证码
        accountNumber: '', // 账号
        password: '', // 密码
        forgetPhone: '', // 忘记密码的手机哈
        forgePassword: '', // 忘记密码的密码哈
        forgetVerifyCode: '' // 忘记密码的验证码哈
      },
      loginFlag: false,
      forgetFlag: false,
      dialogVisible: false,
      verifyRes: null,
      resetInfor: '获取验证码',
      countdown: 60,
      disBtn: false,
      loginText: '密码登录',
      passwordReadonly: true,
      NewpasswordReadonly: true,
      passwordLogin: true, // 是否显示密码登录
      showForget: true, // 显示忘记密码
      rules: {
        phoneNumber: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          { validator: phoneNumberReg, trigger: 'change' }
        ],
        password: [
          { required: false, message: '请填写密码', trigger: 'change' },
          { validator: Passwordreg, trigger: 'change' }
        ],
        forgetPhone: [
          { required: true, message: '请填写手机号', trigger: 'change' },
          { validator: phoneNumberReg, trigger: 'change' }
        ],
        forgePassword: [
          { required: false, message: '请填写密码', trigger: 'change' },
          { validator: Passwordreg, trigger: 'change' }
        ]
      },
      newPasswordTip: false,
      strength: '',
      score: '',
      passwordStrongShow: false
    })

    /**
     * 方法区域
     */
    const initVerifyCode = () => {
      // eslint-disable-next-line camelcase
      const nc_token = ['FFFF0N00000000008FEC', (new Date()).getTime(), Math.random()].join(':')
      // eslint-disable-next-line no-undef,new-cap
      const nc = new noCaptcha({
        // 声明滑动验证需要渲染的目标元素ID。
        renderTo: '#verify-code',
        // 应用类型标识。它和使用场景标识（scene字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的appkey字段值，请务必正确填写。
        appkey: 'FFFF0N00000000008FEC',
        // 使用场景标识。它和应用类型标识（appkey字段）一起决定了滑动验证的业务场景与后端对应使用的策略模型。您可以在人机验证控制台的配置管理页签找到对应的scene值，请务必正确填写。
        scene: 'nc_login',
        // 滑动验证码的主键，请勿将该字段定义为固定值。确保每个用户每次打开页面时，其token值都是不同的。系统默认的格式为：”您的appkey”+”时间戳”+”随机数”。
        token: nc_token,
        // 业务键字段，可为空。为便于线上问题的排查，建议您按照线上问题定位文档中推荐的方法配置该字段值。
        // 语言，默认值为cn（中文）。HTML5应用类型默认支持简体中文、繁体中文、英文语言。
        language: 'cn',
        // customWidth: 280,
        // 内部网络请求的超时时间。一般情况建议保持默认值（10000ms）。
        timeout: 3000,
        // 允许服务器超时重复次数，默认5次。
        times: 5,
        // 验证通过后，验证码组件是否自动隐藏，默认不隐藏（false）。
        bannerHidden: false,
        isEnabled: true,
        // 前端滑动验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
        callback: (data) => {
          const res = { nc_token, csessionid: data.csessionid, sig: data.sig }
          state.verifyRes = res
          // this.getForgetCode()
          loginInshoot()
        },
        error: function (s) {
          console.log(s)
        }
      })
      state.$nc = nc
      nc.upLang('cn', {
        _startTEXT: '请按住滑块，拖动到最右边',
        _yesTEXT: '验证通过',
        _error300: '哎呀，出错了，点击<a href="javascript:__nc.reset()">刷新</a>再来一次',
        _errorNetwork: '网络不给力，请<a href="javascript:__nc.reset()">点击刷新</a>'
      })
    }
    /**
     * 密码登录 手机登录切换
     */
    const changePassword = () => {
      if (!state.passwordLogin) {
        state.loginText = '短信登录'
      } else {
        state.loginText = '密码登录'
      }
      state.passwordLogin = !state.passwordLogin
      setTimeout(() => {
        state.passwordReadonly = !state.passwordReadonly // 密码登录 readonly
      }, 500)
    }
    /**
     * 获取验证码
     */
    const getVerifyCode = (type) => {
      if (type === 'login') {
        if (state.ruleForm.phoneNumber === '') {
          return proxy.$notify({
            title: '系统通知',
            message: '请输入手机号',
            type: 'error'
          })
        }
      } else {
        if (state.ruleForm.forgetPhone === '') {
          return proxy.$notify({
            title: '系统通知',
            message: '请输入手机号',
            type: 'error'
          })
        }
      }

      state.dialogVisible = true
      nextTick(() => {
        initVerifyCode()
      })
    }
    // 倒计时
    const timeOut = () => {
      if (state.countdown === 0) {
        state.disBtn = false
        state.resetInfor = '获取验证码'
        state.countdown = 60
        state.verifyRes = null
        state.$nc.reset()
      } else {
        state.disBtn = true
        state.countdown--
        state.resetInfor = state.countdown + 's'
        setTimeout(() => {
          timeOut()
        }, 1000)
      }
    }
    /**
     * 点击登录
     */
    const goLogin = () => {
      LoginRuleForm.value.validate((valid) => {
        if (valid) {
          state.dialogVisible = true
          nextTick(() => {
            initVerifyCode()
          })
        } else {
          return proxy.$notify({
            title: '系统通知',
            message: '请正确填写表单',
            type: 'error'
          })
        }
      })
    }
    /**
     * 点击忘记密码
     */
    const forgetPassword = () => {
      state.showForget = !state.showForget
      state.passwordReadonly = true // 密码登录 密码框设置为readonly
      setTimeout(() => {
        state.NewpasswordReadonly = false // 忘记密码登录 密码框设置为readonly为false
      }, 500)
    }
    /**
     * 返回登录
     */
    const goBackLoginBox = () => {
      state.showForget = !state.showForget
      state.NewpasswordReadonly = true
      state.passwordLogin = false
      state.passwordReadonly = true
      state.loginText = '密码登录'
    }
    /**
     * 忘记密码模块儿登录
     */
    const forgetLogin = () => {
      LoginRuleForm.value.validate((valid) => {
        if (valid) {
          router.push('/index')
        } else {
          return proxy.$notify({
            title: '系统通知',
            message: '请正确填写表单',
            type: 'error'
          })
        }
      })
    }

    const passwordGrade = (pwd) => {
      var score = 0
      var regexArr = ['[0-9]', '[a-z]', '[A-Z]', '[\\W_]']
      var repeatCount = 0
      var prevChar = ''

      var len = pwd.length
      score += len > 18 ? 18 : len
      // eslint-disable-next-line no-eval
      for (let i = 0, num = regexArr.length; i < num; i++) { if (eval('/' + regexArr[i] + '/').test(pwd)) score += 4 }
      for (let i = 0, num = regexArr.length; i < num; i++) {
        // eslint-disable-next-line no-eval
        if (pwd.match(eval('/' + regexArr[i] + '/g')) && pwd.match(eval('/' + regexArr[i] + '/g')).length >= 2) score += 2
        // eslint-disable-next-line no-eval
        if (pwd.match(eval('/' + regexArr[i] + '/g')) && pwd.match(eval('/' + regexArr[i] + '/g')).length >= 5) score += 2
      }
      for (let i = 0, num = pwd.length; i < num; i++) {
        if (pwd.charAt(i) === prevChar) repeatCount++
        else prevChar = pwd.charAt(i)
      }
      score -= repeatCount * 1
      if (score < 20) {
        state.strength = '弱'
      }
      if (score < 35 && score > 20) {
        state.strength = '中'
      }
      if (score < 50 && score > 35) {
        state.strength = '强'
      }
      state.score = score
    }
    const passwordChange = (val) => {
      if (!val) {
        state.newPasswordTip = true
      } else {
        state.newPasswordTip = false
      }
      passwordGrade(val)
    }
    const strong = () => {
      if (state.score >= 14) {
        state.passwordStrongShow = true
      } else {
        state.passwordStrongShow = false
      }
    }
    const showVisble = () => {
      if (!state.ruleForm.forgePassword) {
        state.newPasswordTip = true
      }
    }

    /**
     * 进入首页
     */
    const goHome = () => {
      registerNameForm.value.validate((valid) => {
        if (valid) {
          registerInshoot()
        } else {
          return proxy.$notify({
            title: '系统通知',
            message: '昵称格式不正确',
            type: 'error'
          })
        }
      })
    }

    /**
     * 登录 密码输入框
     */
    const showPwd = (type) => {
      if (type === 'login') {
        state.loginFlag = !state.loginFlag
      } else {
        state.forgetFlag = !state.forgetFlag
      }
    }

    /**
     * 登录接口
     */
    const loginInshoot = async () => {
      try {
        const sd = await proxy.$api.loginAdmin(
          {
            type: 2,
            mobile: state.ruleForm.accountNumber,
            password: md5(state.ruleForm.password)
          })
        const hsucInfo = {
          token: sd.data.access_token,
          name: sd.data.name,
          user_id: sd.data.user_info.user_id,
          status: sd.data.user_info.status,
          user_face: sd.data.user_info.user_face,
          garden: sd.data.user_info.garden,
          email: sd.data.user_info.email,
          mobile: sd.data.user_info.mobile
        }
        localStorage.setItem('HSUC_LOGIN_INFO', JSON.stringify(hsucInfo))
        router.push('/personalaudit')
      } catch (err) {
        state.dialogVisible = false
        state.verifyRes = null
        state.$nc.reset()
        return proxy.$notify({
          title: '系统通知',
          message: err,
          type: 'error'
        })
      }
    }

    /**
     * 注册接口
     */
    const registerInshoot = async () => {
      try {
        await proxy.$api.postRegister({ tel: state.ruleForm.phoneNumber, verifyCode: state.ruleForm.verifyCode })
        router.push('/index')
      } catch (err) {
        return proxy.$notify({
          title: '系统通知',
          message: err.message,
          type: 'error'
        })
      }
    }

    return {
      ...toRefs(state),
      initVerifyCode,
      changePassword,
      getVerifyCode,
      timeOut,
      goLogin,
      forgetPassword,
      goBackLoginBox,
      forgetLogin,
      passwordGrade,
      passwordChange,
      strong,
      showVisble,
      goHome,
      LoginRuleForm,
      registerNameForm,
      showPwd
    }
  }
})
</script>
<style lang='scss' scoped>
.newLogin {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: url("~@/assets/img/login_back.png") no-repeat;
  background-size: cover;
  position: relative;
  .logo {
    width: 139px;
    position: absolute;
    top: 46px;
    left: 142px;
    img {
      width: 100%;
    }
  }
  .login-inputs {
    width: 496px;
    background: #ffffff;
    border: 1px solid #d3d3d4;
    opacity: 0.94;
    border-radius: 15px;
    position: relative;
    margin: auto;
    top: 15%;
    .loginBox {
      padding: 66px 38px;
      .login-title {
        font-size: 36px;
        font-weight: bold;
        color: #010000;
        text-align: center;
      }
      .yzm-item {
        position: relative;
      }
      .form-item-box {
        margin-top: 36px;
        .get-code-btn {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 14px;
          color: #ff4d4d !important;
          border: none;
          background-color: transparent;
        }
        .eyes-icon {
          font-size: 20px;
        }
      }
    }
    .login-btn {
      width: 100%;
      height: 52px;
      margin-top: 62px;
    }
    .login-type-btns {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
    }
    .forget-password {
      padding: 24px 20px;
      .goLoginBox {
        display: flex;
        i {
          font-size: 26px;
          color: #999999;
          cursor: pointer;
          &:hover {
            color: #ff6565;
          }
        }
      }
      .contentInputs {
        width: 95%;
        margin: 0 auto;
      }
      .login-title {
        font-size: 36px;
        font-weight: bold;
        color: #010000;
        margin-top: 10px;
      }
      .yzm-item {
        position: relative;
      }
      .form-item-box {
        margin-top: 36px;
        .get-code-btn {
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 14px;
          color: #ff4d4d !important;
          border: none;
          background-color: transparent;
        }
      }
      .has-number {
        font-size: 14px;
        color: #666666;
        margin-top: 27px;
        margin-bottom: 50px;
        span {
          color: #ff4d4d;
          cursor: pointer;
        }
      }
    }
    .verify-code-box {
      width: 100%;
      padding: 39px 0px;
      background-color: #fff;
      box-shadow: 0px 0px 20px 0px rgba(129, 137, 152, 0.36);
      border-radius: 10px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      .box-main {
        width: 85%;
        margin: 0 auto;
        position: relative;
        .closeicon {
          font-size: 20px;
          color: #666;
          position: absolute;
          right: -10px;
          top: -20px;
          cursor: pointer;
        }
        .verify-code-box-title {
          font-size: 16px;
          font-weight: 400;
          color: #1d84fa;
        }
        #verify-code {
          width: 100%;
          margin-top: 20px;
        }
      }
    }
  }
  .creatName {
    width: 496px;
    background: #ffffff;
    border: 1px solid #d3d3d4;
    opacity: 0.94;
    border-radius: 15px;
    position: relative;
    padding: 67px 41px;
    .creat-title {
      font-size: 34px;
      font-weight: bold;
      color: #010000;
    }
    .creatName-input {
      display: flex;
      margin-top: 36px;
      .input-label {
        width: 80px;
        font-size: 14px;
        font-weight: 400;
        line-height: 46px;
        color: #666666;
      }
    }
    .zhuceBtn {
      width: 100%;
      height: 52px;
      line-height: 52px;
      font-size: 18px;
      margin-top: 100px;
    }
    .read {
      text-align: right;
      font-size: 14px;
      color: #666666;
      margin-top: 27px;
    }
    .user-xieyi {
      color: #1890ff;
    }
  }
}

.login-btn ::v-deep(.el-button) {
  width: 100%;
  line-height: 52px;
  height: 52px;
  padding: 0;
  display: block;
  font-size: 18px;
}
.login-type-btns::v-deep(.el-button) {
  display: block;
  color: #666;
  font-size: 14px;
}

#verify-code::v-deep(.nc_wrapper) {
  width: 100% !important;
}

.creatName-input::v-deep(.el-form) {
  width: 100%;
  display: flex;
  .el-form-item {
    display: flex;
    width: 100%;
    .el-form-item__label {
      width: 80px;
      text-align: left;
    }
    .el-form-item__content {
      flex: 1;
    }
  }
}

.newLogin::v-deep(.el-input) {
  width: 86%;
}
.newLogin::v-deep(.el-input__inner) {
  height: 46px;
  line-height: 46px;
}
.newLogin::v-deep(.el-input-group__append) {
  background-color: #fff;
}
.strength_box {
  font-size: 14px;
  display: flex;
  margin-top: 20px;
  color: #666;
  .defBackground {
    background: #ccc;
    display: inline-block;
    height: 3px;
    width: 6%;
    margin-right: 1px;
  }
  .red {
    background: #ff0000;
  }
  .yellow {
    background: #ffd100;
  }
  .green {
    background: #52c41a;
  }
  .password_border {
    display: inline-block;
    height: 4px;
    width: 200px;
    margin-left: 15px;
  }
  .hhh {
    background-color: #f2f2f2;
  }
}
.disable-btn {
  background-color: #f2f3f9;
  color: #b9bed3;
  border: none;
  &:hover {
    background-color: #f2f3f9;
    color: #b9bed3;
    border: none;
  }
}
.agreement {
  padding: 0px 15px;
  img {
    width: 80%;
    display: block;
    margin: 0 auto;
  }
}
.newLogin::v-deep .el-input__suffix {
  right: 20px;
  top: 5px;
}
.newLogin::v-deep(.el-form-item__label) {
  position: relative;
  top: 5px;
}
.newLogin::v-deep
  .el-form-item.is-required:not(.is-no-asterisk)
  > .el-form-item__label:before,
.el-form-item.is-required:not(.is-no-asterisk)
  .el-form-item__label-wrap
  > .el-form-item__label:before {
  display: none;
}
.icp {
  height: 40px;
  line-height: 40px;
  font-size: 12px;
  font-family: PingFang-SC-Bold;
  color: #f0f0f0;
  text-align: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
}
a {
  text-decoration: none;
}
</style>
